import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import AdvanceCoinManageWrapper from "wrappers/AdvanceCoinManageWrapper";

import AuthWrapper from "wrappers/AuthWrapper";
import CoinManageWrapper from "wrappers/CoinManageWrapper";
import SettingWrapper from "wrappers/SettingWrapper";
import TransferCoinWrapper from "wrappers/TransferCoinWrapper";
const CoinManage = lazy(() => import("pages/CoinManage"));
const TransactionHistory = lazy(() => import("pages/TransactionHistory"));
const Error = lazy(() => import("pages/Error"));
const NotFoundPage = lazy(() => import("pages/NotFoundPage"));
const Discount = lazy(() => import("pages/Discount"));
const Gift = lazy(() => import("pages/Gift"));
const TransferCoin = lazy(() => import("pages/TransferCoin"));
const Decentralization = lazy(() => import("pages/Decentralization"));
const LogAction = lazy(() => import("pages/LogAction"));
const EmployeeWallet = lazy(() => import("pages/EmployeeWallet"));
const SystemWallet = lazy(() => import("pages/SystemWallet"));
const SystemWalletDetail = lazy(
  () => import("pages/SystemWallet/TransferCoinDetail")
);
const TransferCoinHistory = lazy(() => import("pages/TransferCoinHistory"));
const DiscountCU = lazy(() => import("pages/Discount/DiscountCU"));
const DiscountDetail = lazy(() => import("pages/Discount/DiscountDetail"));
const GiftCU = lazy(() => import("pages/Gift/GiftCU"));
const Dashboard = lazy(() => import("pages/Dashboard"));
const TransactionHistoryDetail = lazy(
  () => import("pages/TransactionHistory/TransactionHistoryDetail")
);
const DetailPermission = lazy(
  () => import("pages/Decentralization/DetailPermission")
);
const PermissionCU = lazy(() => import("pages/Decentralization/PermissionCU"));
const Metadata = lazy(() => import("pages/Metadata"));
const AdvanceCoinHistory = lazy(
  () => import("pages/AdvanceCoinManage/AdvanceCoinHistory")
);
const AdvanceCoinList = lazy(
  () => import("pages/AdvanceCoinManage/AdvanceCoinList")
);

export default function AppWrapper() {
  return (
    <div className="root-wrapper">
      <Routes>
        <Route path="/" element={<AuthWrapper />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/coin-manage" element={<CoinManageWrapper />}>
            <Route path="/coin-manage/system-coin" element={<CoinManage />} />
            <Route
              path="/coin-manage/employee-wallet"
              element={<EmployeeWallet />}
            />
            <Route
              path="/coin-manage/system-wallet"
              element={<SystemWallet />}
            />
          </Route>
          <Route
            path="/coin-manage/system-wallet/:id"
            element={<SystemWalletDetail />}
          />
          <Route path="/transaction-history" element={<TransactionHistory />} />
          <Route
            path="/transaction-history/:id"
            element={<TransactionHistoryDetail />}
          />
          <Route path="/settings" element={<SettingWrapper />}>
            <Route path="/settings/discount" element={<Discount />} />
            <Route path="/settings/gift" element={<Gift />} />
            <Route
              path="/settings/decentralization"
              element={<Decentralization />}
            />
            <Route path="/settings/metadata" element={<Metadata />} />
          </Route>
          <Route
            path="/settings/decentralization/add"
            element={<PermissionCU />}
          />
          <Route
            path="/settings/decentralization/detail/:id"
            element={<DetailPermission />}
          />
          <Route path="/settings/discount/add" element={<DiscountCU />} />
          <Route path="/settings/discount/edit/:id" element={<DiscountCU />} />
          <Route
            path="/settings/discount/detail/:id"
            element={<DiscountDetail />}
          />
          <Route path="/settings/gift/add" element={<GiftCU />} />
          <Route path="/settings/gift/edit/:id" element={<GiftCU />} />
          <Route path="/transfer-coin" element={<TransferCoinWrapper />}>
            <Route path="/transfer-coin/transfer" element={<TransferCoin />} />
            <Route
              path="/transfer-coin/history"
              element={<TransferCoinHistory />}
            />
          </Route>
          <Route
            path="/advance-coin-manage"
            element={<AdvanceCoinManageWrapper />}
          >
            <Route
              path="/advance-coin-manage/list"
              element={<AdvanceCoinList />}
            />
            <Route
              path="/advance-coin-manage/history"
              element={<AdvanceCoinHistory />}
            />
          </Route>
        </Route>
        <Route path="/log-action" element={<LogAction />} />
        <Route path="/error" element={<Error />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}
