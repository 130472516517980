import { Menu } from "antd";
import type { MenuProps } from "antd";
import { useLocation } from "react-router-dom";

import styles from "./styles.module.scss";

interface IProps {
  items: MenuProps["items"];
}

export default function SubHeader({ items }: IProps) {
  const location = useLocation();

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[location.pathname.split("/")[2]]}
      className={styles.subHeaderWrapper}
      items={items}
      disabledOverflow
    ></Menu>
  );
}
