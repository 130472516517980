import { useQuery } from "react-query";

import { getListPermission } from "api/decentralization";

export default function usePermission() {
  const {
    data: permissions,
    refetch: refetchPermissions,
    isFetching: fetchingPermissions,
  } = useQuery("permissions", getListPermission);
  return { permissions, refetchPermissions, fetchingPermissions };
}
