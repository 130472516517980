import { sendDelete, sendGet, sendPost, sendPut } from "./axios";

export const getMembers = (params: any) =>
  sendGet("/cms/admin-auth/admin-coin", params);
export const updateDecentralization = (payload: any) =>
  sendPut("/cms/permission/update-user-type", payload);
export const getAllMembers = () => sendGet("/cms/admin-auth/members");
export const getListRole = (params: any) =>
  sendGet("/cms/authorization/list-role", params);
export const getListPermission = () => sendGet("/cms/authorization/permission");
export const createRole = (payload: any) =>
  sendPost("/cms/authorization/create-role", payload);
export const deleteRole = (id: number) =>
  sendDelete(`/cms/authorization/delete-role/${id}`);
export const getRoleDetail = (id?: number | string) =>
  sendGet(`/cms/authorization/role-detail-permission/${id}`);
export const getListMemberRole = (params: any, id?: number | string) =>
  sendGet(`/cms/authorization/role-detail-member/${id}`, params);
export const deleteMemberRole = (id: number, payload: any) =>
  sendDelete(`/cms/authorization/role-member/${id}`, payload);
export const updateRole = (payload: any, id?: number | string) =>
  sendPut(`/cms/authorization/update-role/${id}`, payload);
export const addMemberRole = (payload: any) =>
  sendPost("/cms/authorization/add-member", payload);
export const checkRole = (payload: any) =>
  sendPost("/cms/authorization/check-role", payload);
export const checkRoleName = (payload: any) =>
  sendPost("/cms/authorization/check-name-role", payload);
