import { Suspense, useEffect, useMemo, useState } from "react";
import { Link, useOutlet } from "react-router-dom";
import { MenuProps } from "antd";
import { useTranslation } from "react-i18next";

import SubHeader from "components/SubHeader";
import usePermission from "utils/hooks/usePermission";
import useProfile from "utils/hooks/useProfile";
import { PermissionType, UserType } from "constants/enum";

export default function SettingWrapper() {
  const outlet = useOutlet();
  const [t] = useTranslation();
  const { permissions, fetchingPermissions } = usePermission();
  const { profile, fetchingProfile } = useProfile(true);
  const [curItems, setCurItems] = useState<MenuProps["items"]>();

  const items: MenuProps["items"] = useMemo(() => {
    return [
      {
        key: "discount",
        label: <Link to="/settings/discount">{t("tab.discount")}</Link>,
      },
      {
        key: "gift",
        label: <Link to="/settings/gift">{t("tab.gift")}</Link>,
      },
      {
        key: "decentralization",
        label: (
          <Link to="/settings/decentralization">
            {t("tab.decentralization")}
          </Link>
        ),
      },
      {
        key: "metadata",
        label: (
          <Link to="/settings/metadata">
            {t("tab.metadata")}
          </Link>
        ),
      },
    ];
  }, []);

  useEffect(() => {
    let itemsTemp = items;
    if (profile && profile?.userType === UserType.SUPER_ADMIN) {
      return setCurItems(items);
    }
    if (permissions?.data && !fetchingPermissions) {
      if (permissions?.data?.includes(PermissionType.DISCOUNT_ACCESS_DENIED)) {
        itemsTemp = itemsTemp?.filter((item) => item?.key !== "discount");
      }
      if (permissions?.data?.includes(PermissionType.GIFT_ACCESS_DENIED)) {
        itemsTemp = itemsTemp?.filter((item) => item?.key !== "gift");
      }
      if (
        permissions?.data?.includes(PermissionType.PERMISSION_ACCESS_DENIED)
      ) {
        itemsTemp = itemsTemp?.filter(
          (item) => item?.key !== "decentralization"
        );
      }
      return setCurItems(itemsTemp);
    }
  }, [fetchingPermissions, fetchingProfile]);

  return (
    <div className="sub-wrapper">
      <div className="sub-menu">
        <SubHeader items={curItems} />
      </div>
      <Suspense fallback={null}>{outlet}</Suspense>
    </div>
  );
}
