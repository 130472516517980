import { Row, List, Badge, Avatar, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { INotification } from "constants/interface";
import { GetNotiType } from "constants/enum";
import styles from "./styles.module.scss";
import { convertUTCToLocalString } from "utils/helper";

interface IProps {
  lists: INotification[];
  type: GetNotiType;
  onSelectRow: (id: number) => void;
}

export default function ListNotification(props: IProps) {
  const handleRowSelect = (item: INotification) => {
    if (!item.is_read) props.onSelectRow(item.id);
  };

  if (props.lists.length === 0) return null;
  return (
    <>
      <Row>
        <h3 className={`${styles.contentTitle} ${styles.title}`}>
          {props.type === GetNotiType.TODAY ? "Hôm nay" : "Cũ hơn"}
        </h3>
      </Row>
      <List
        dataSource={props.lists}
        className="mb-10"
        renderItem={(item: INotification) => (
          <List.Item
            className={styles.contentItem}
            onClick={() => handleRowSelect(item)}
          >
            <List.Item.Meta
              avatar={
                <>
                  {!item.is_read ? (
                    <Badge color="gold" className={styles.badge} />
                  ) : (
                    <div
                      style={{ width: 18, height: 22, display: "inline-block" }}
                    ></div>
                  )}
                  <Avatar
                    src={item.sender_avatar}
                    icon={<UserOutlined />}
                    size={52}
                  />
                </>
              }
              title={
                <span className={`${styles.title} ${styles.name}`}>
                  {item.request_name}
                </span>
              }
              description={
                <>
                  <div className={styles.message}>{item.message}</div>
                  <div className={styles.createAt}>
                    {convertUTCToLocalString(item.created_at)}
                  </div>
                </>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
}
