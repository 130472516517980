import { useQuery } from "react-query";

import { getListSystem } from "api/system";

export default function useSystem() {
  const { data: listSystem, isLoading: loadingSystem } = useQuery(
    ["listSystem"],
    getListSystem
  );
  return { listSystem, loadingSystem };
}
