import configs from "constants/config";
import { sendGet, sendPut } from "./axios";

export const getNotifications = (params: any) =>
  sendGet(`${configs.API_COMMON}/api/notifications/`, params);
export const updateRead = (payload: any) =>
  sendPut(`${configs.API_COMMON}/api/update-read/`, payload);
export const updateReadAll = (payload: any) =>
  sendPut(`${configs.API_COMMON}/api/see-all/`, payload);
export const getNotiInfo = (payload: any) =>
  sendGet(`${configs.API_COMMON}/api/total/notifications`, payload);
export const updateView = (payload: any) =>
  sendPut(`${configs.API_COMMON}/api/update-view/`, payload);
