import { Avatar, Col, List, Row, Skeleton, Spin } from "antd";
import { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "lodash";
import moment from "moment";
import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import NotificationIcon from "assets/images/notification-icon.svg";
import ME_ICON from "assets/images/acms-me.svg";
import BO_ICON from "assets/images/acms-bo.svg";
import WORKFLOW_ICON from "assets/images/acms-workflow.svg";
import styles from "./styles.module.scss";
import { getNotifications, getNotiInfo } from "api/notification";
import { INotification, ISystem } from "constants/interface";
import { compareDate } from "utils/helper";
import ListNotification from "../ListNotification";
import { GetNotiType } from "constants/enum";
import { INotiInfoItem } from "../PageHeader/";
import { updateRead, updateView, updateReadAll } from "api/notification";
import configs from "constants/config";

interface IState {
  isOutData: boolean;
  notificationAll: INotification[];
  page: number;
  size: number;
  system_id?: number;
  user_id?: number;
}
interface IProps {
  userId?: number;
  listSystem?: any;
}
interface IQueryData {
  countNotView: number;
  current_page: number;
  limit_from: number;
  limit_to: number;
  list_noti: INotification[];
  total: number;
  total_row: number;
}

const apps = [
  {
    id: 1,
    image: ME_ICON,
    title: "ACMS ME",
    subTitle: "Cổng nhân viên",
    systemId: 1,
    url: configs.CMS_ME_DOMAIN,
  },
  {
    id: 2,
    image: BO_ICON,
    title: "ACMS BO",
    subTitle: "Nhân sự",
    systemId: 2,
    url: configs.CMS_BO_DOMAIN,
  },
  {
    id: 3,
    image: WORKFLOW_ICON,
    title: "ACMS Workflow",
    subTitle: "Tạo đề xuất",
    systemId: 4,
    url: configs.CMS_WORK_FLOW_DOMAIN,
  },
];

const Notification = (props: IProps) => {
  const initState: IState = {
    page: 1,
    size: 20,
    user_id: props.userId,
    system_id: 1,
    isOutData: false,
    notificationAll: [],
  };

  const queryClient = useQueryClient();
  const [state, setState] = useState<IState>(initState);
  const [open, setOpen] = useState(false);
  const { data: notifications, isFetching } = useQuery<IQueryData>(
    ["notifications", _.pick(state, ["page", "size", "user_id", "system_id"])],
    () =>
      getNotifications(_.pick(state, ["page", "size", "user_id", "system_id"]))
  );
  const { data: notiInfo } = useQuery<INotiInfoItem[]>("notiInfo", () =>
    getNotiInfo({
      user_id: props.userId,
    })
  );
  const { mutate: readNotification } = useMutation((payload: any) =>
    updateRead(payload)
  );
  const { mutate: viewNotification } = useMutation(
    (payload: any) => updateView(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("notiInfo");
      },
    }
  );
  const { mutate: readNotificationAll } = useMutation(
    (payload: any) => updateReadAll(payload),
    {
      onSuccess: () => {
        setState({
          ...state,
          isOutData: false,
          notificationAll: [],
          page: 1,
          size: 10,
        });
      },
    }
  );

  useEffect(() => {
    if (notifications?.list_noti && !isFetching) {
      setState({
        ...state,
        isOutData: notifications.total < state.size,
        notificationAll: [...state.notificationAll, ...notifications.list_noti],
      });
    }
  }, [isFetching]);

  const loadMoreData = () => {
    if (!isFetching) setState({ ...state, page: state.page + 1 });
  };
  const getTodayNoti = () => {
    return _.filter(state.notificationAll, (notification: INotification) =>
      compareDate(moment(notification.created_at), moment())
    );
  };
  const getOldNoti = () => {
    return _.filter(
      state.notificationAll,
      (notification: INotification) =>
        !compareDate(moment(notification.created_at), moment())
    );
  };
  const getAmountNotView = (systemId: number) => {
    const notiItemInfo = _.filter(notiInfo, (noti: INotiInfoItem) => {
      return noti.system_id === systemId;
    });
    if (notiItemInfo.length > 0) {
      return notiItemInfo[0];
    }
  };
  const getAmountNotViewAll = () => {
    return _.reduce(
      notiInfo,
      (prev: number, curr: INotiInfoItem) =>
        !curr.is_viewed ? prev + 1 : prev,
      0
    );
  };
  const handleAppClick = (item: ISystem) => {
    setState({
      ...initState,
      system_id: item.systemId,
    });
    if (getAmountNotView(item.systemId)?.is_viewed === 0) {
      viewNotification({
        system_id: item.systemId,
        user_id: state.user_id,
      });
    }
  };
  const handleSelectRow = (id: number) => {
    readNotification({
      id,
      user_id: state.user_id,
    });
    setState({
      ...state,
      notificationAll: state.notificationAll.map(
        (notification: INotification) => {
          if (notification.id === id) {
            return { ...notification, is_read: 1 };
          }
          return notification;
        }
      ),
    });
  };
  const handleViewAll = () => {
    readNotificationAll({
      system_id: state.system_id,
      user_id: state.user_id,
    });
  };
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipChange = () => {
    if (!open && !getAmountNotView(state.system_id || 1)?.is_viewed) {
      viewNotification({
        system_id: state.system_id,
        user_id: state.user_id,
      });
    }
    setOpen(!open);
  };

  return (
    <div className={styles.notiContainer}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            title={
              <>
                <Row
                  justify="space-between"
                  align="middle"
                  className={styles.headerNoti}
                >
                  <Col>
                    <div className={styles.notiTitle}>Thông báo</div>
                  </Col>
                  <Col>
                    <span className={styles.viewAll} onClick={handleViewAll}>
                      Xem tất cả
                    </span>
                  </Col>
                </Row>
                <Row className={styles.contentNoti}>
                  <Col span={9} className={styles.systemContainer}>
                    <Row>
                      <h3 className={styles.contentTitle}> Hệ thống</h3>
                    </Row>
                    <Row>
                      <List
                        className={styles.optionContainer}
                        dataSource={
                          props.listSystem?.data?.length > 0
                            ? props.listSystem?.data
                            : apps
                        }
                        renderItem={(item: ISystem) => (
                          <List.Item
                            key={item.id}
                            className={classNames(styles.groupOption, {
                              [styles.active]:
                                state.system_id === item.systemId,
                            })}
                            onClick={() => handleAppClick(item)}
                          >
                            <List.Item.Meta
                              avatar={
                                <>
                                  <Avatar src={item.image} size="large" />
                                </>
                              }
                              title={
                                <span className={`${styles.title}`}>
                                  {item.title}
                                </span>
                              }
                              description={
                                <>
                                  <p className="mg-0 fs-12">{item.subTitle}</p>
                                </>
                              }
                            />
                            {getAmountNotView(item.systemId)?.is_viewed ===
                            0 ? (
                              <div className={styles.optionAmount}>
                                <p>{getAmountNotView(item.systemId)?.total}</p>
                              </div>
                            ) : null}
                          </List.Item>
                        )}
                      />
                    </Row>
                  </Col>
                  <Col
                    className={styles.contentContainer}
                    span={15}
                    id="scroll-notifications"
                  >
                    {isFetching && state.page === 1 ? (
                      <Spin size="large" />
                    ) : (
                      <InfiniteScroll
                        dataLength={state.notificationAll.length}
                        next={loadMoreData}
                        hasMore={!state.isOutData}
                        loader={
                          <Skeleton avatar paragraph={{ rows: 1 }} active />
                        }
                        endMessage={
                          <div className={styles.noData}>Không có dữ liệu</div>
                        }
                        scrollableTarget="scroll-notifications"
                      >
                        <ListNotification
                          lists={getTodayNoti()}
                          type={GetNotiType.TODAY}
                          onSelectRow={handleSelectRow}
                        />
                        <ListNotification
                          lists={getOldNoti()}
                          type={GetNotiType.OLD}
                          onSelectRow={handleSelectRow}
                        />
                      </InfiniteScroll>
                    )}
                  </Col>
                </Row>
              </>
            }
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow={true}
            placement="bottom-end"
          >
            <div
              className={styles.notificationIcon}
              onClick={handleTooltipChange}
            >
              {!getAmountNotViewAll() ? null : (
                <span>{getAmountNotViewAll()}</span>
              )}
              <img src={NotificationIcon} alt="" />
            </div>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default Notification;
