import Cookies from "js-cookie";
import configs from "constants/config";

export const USER_TOKEN = "tokenACMSCoin";
export const USER_REFRESH_TOKEN = "refreshTokenACMSCoin";

export const EXPIRES = 2;

const setItem = (key: string, value: any) => {
  Cookies.set(key, value, {
    expires: EXPIRES,
    path: "/",
    domain: configs.SUB_DOMAIN_TOKEN,
  });
};

const getItem = (key: string) => {
  return Cookies.get(key);
};

const removeItem = (key: string) => {
  Cookies.remove(key, { path: "/", domain: configs.SUB_DOMAIN_TOKEN });
};

const setToken = (value: any) => {
  setItem(USER_TOKEN, value);
};

const clearToken = () => {
  removeItem(USER_TOKEN);
};

const getToken = () => {
  return getItem(USER_TOKEN);
};

const getRefreshToken = () => getItem(USER_REFRESH_TOKEN);

const storageFC = {
  setItem,
  getItem,
  removeItem,
  setToken,
  clearToken,
  getToken,
  getRefreshToken,
};
export default storageFC;
