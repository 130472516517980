import Axios from "axios";
import configs from "constants/config";
import { logout } from "utils/helper/authentication";
import { handleErrorMessage } from "i18n/";
import { ErrorCode } from "constants/enum";
import { history } from "../App";

import storageFC from "utils/helper/storage";

const axiosInstance = Axios.create({
  timeout: 3 * 60 * 1000,
  baseURL: configs.API_DOMAIN,
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    // eslint-disable-next-line no-param-reassign
    const token = storageFC.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    const originalConfig = error.config;
    if (error.response?.data?.errorCode === ErrorCode.Auth_Acms_Failed) {
      alert("Redirect to login sso!");
      return logout();
    }
    if (error.response?.data?.errorCode === ErrorCode.Access_Denied) {
      return history.push("/error");
    }
    if (error.response?.data?.errorCode === ErrorCode.Unauthorized) {
      return Axios.post(`${configs.API_DOMAIN}/cms/admin-auth/login`, {
        tokenSSO: storageFC.getItem("token"),
      })
        .then((res: any) => {
          if (res?.data?.data?.token) {
            const data = res.data.data;
            storageFC.setToken(data.token);
            originalConfig.headers.Authorization = `Bearer ${data.token}`;
            return Axios(originalConfig);
          }
        })
        .catch((error) => {
          if (error.response?.data?.errorCode === ErrorCode.Auth_Acms_Failed) {
            alert("Redirect to login sso!");
            return logout();
          }
          if (error.response?.data?.errorCode === ErrorCode.Access_Denied) {
            return history.push("/error");
          }
          return Promise.reject(error);
        });
    }
    handleErrorMessage(error);
    return Promise.reject(error);
  }
);

export const sendGet = (url: string, params?: any) =>
  axiosInstance.get(url, { params }).then((res) => res.data);
export const sendPost = (url: string, params?: any, queryParams?: any) =>
  axiosInstance
    .post(url, params, { params: queryParams })
    .then((res) => res.data);
export const sendPut = (url: string, params?: any) =>
  axiosInstance.put(url, params).then((res) => res.data);
export const sendPatch = (url: string, params?: any) =>
  axiosInstance.patch(url, params).then((res) => res.data);
export const sendDelete = (url: string, params?: any) =>
  axiosInstance.delete(url, { params }).then((res) => res.data);
