import SortIcon from "./sort-icon.svg";
import SortTop from "./sort-top.svg";
import SortDown from "./sort-down.svg";
import ArrangeCircle from "./arrange-circle-2.svg";
import GiftIcon from "./gift.svg";
import ChartIcon1 from "./chart-icon-1.svg";
import ChartIcon2 from "./chart-icon-2.svg";
import ChartIcon3 from "./chart-icon-3.svg";
import ArrowBottom from "./arrow-bottom.svg";
import AvatarChartDefault from "./avatar-chart-default.svg";
import BackIcon from "./back-icon.svg";
import AddIcon from "./add.svg";
import DropdownIcon from "./dropdown-icon.svg";
import EditIcon from "./edit-2.svg";
import TrashBlackIcon from "./trash-black.svg";
import SearchIcon from "./search-icon.svg";
import eyeIcon from "./eye.svg";
import recoveryCoinIcon from "./recovery-coin-icon.svg";

export const imageObjs = {
  SortIcon,
  SortTop,
  SortDown,
  ArrangeCircle,
  GiftIcon,
  ChartIcon1,
  ArrowBottom,
  ChartIcon2,
  ChartIcon3,
  AvatarChartDefault,
  BackIcon,
  AddIcon,
  DropdownIcon,
  EditIcon,
  TrashBlackIcon,
  SearchIcon,
  eyeIcon,
  recoveryCoinIcon,
};
