import { Suspense } from "react";
import { Link, useOutlet } from "react-router-dom";
import { MenuProps } from "antd";
import { useState, useEffect } from "react";

import SubHeader from "components/SubHeader";
import usePermission from "utils/hooks/usePermission";
import useProfile from "utils/hooks/useProfile";
import { PermissionType, UserType } from "constants/enum";

const items: MenuProps["items"] = [
  {
    key: "system-coin",
    label: <Link to="/coin-manage/system-coin">Coin hệ thống</Link>,
  },
  {
    key: "employee-wallet",
    label: <Link to="/coin-manage/employee-wallet">Ví nhân viên</Link>,
  },
  {
    key: "system-wallet",
    label: <Link to="/coin-manage/system-wallet">Ví hệ thống</Link>,
  },
];

export default function CoinManageWrapper() {
  const outlet = useOutlet();
  const { permissions, fetchingPermissions } = usePermission();
  const { profile, fetchingProfile } = useProfile(true);
  const [curItems, setCurItems] = useState<MenuProps["items"]>([]);

  useEffect(() => {
    let itemsTemp = items;
    if (profile && profile?.userType === UserType.SUPER_ADMIN) {
      return setCurItems(items);
    }
    if (permissions?.data && !fetchingPermissions) {
      if (permissions?.data?.includes(PermissionType.SYSTEM_ACCESS_DENIED)) {
        itemsTemp = itemsTemp?.filter((item) => item?.key !== "system-coin");
      }
      if (
        permissions?.data?.includes(PermissionType.WALLET_MEMBER_ACCESS_DENIED)
      ) {
        itemsTemp = itemsTemp?.filter(
          (item) => item?.key !== "employee-wallet"
        );
      }
      if (
        permissions?.data?.includes(PermissionType.WALLET_SYSTEM_ACCESS_DENIED)
      ) {
        itemsTemp = itemsTemp?.filter((item) => item?.key !== "system-wallet");
      }
      return setCurItems(itemsTemp);
    }
  }, [fetchingPermissions, fetchingProfile]);

  return (
    <div className="sub-wrapper">
      <div className="sub-menu">
        <SubHeader items={curItems} />
      </div>
      <Suspense fallback={null}>{outlet}</Suspense>
    </div>
  );
}
