const configs = {
  APP_ENV: process.env.REACT_APP_ENV,
  API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
  API_DOMAIN_LOGIN: process.env.REACT_APP_API_LOGIN,
  CMS_COIN_DOMAIN: process.env.REACT_APP_CMS_COIN_DOMAIN,
  SUB_DOMAIN_TOKEN: process.env.REACT_APP_SUB_DOMAIN_TOKEN,
  CMS_BO_DOMAIN: process.env.REACT_APP_CMS_BO_DOMAIN,
  CMS_ME_DOMAIN: process.env.REACT_APP_CMS_ME_DOMAIN,
  CMS_RECRUIT_DOMAIN: process.env.REACT_APP_CMS_RECRUIT_DOMAIN,
  CMS_WORK_FLOW_DOMAIN: process.env.REACT_APP_CMS_WORK_FLOW_DOMAIN,
  CMS_ACCOUNTING_DOMAIN: process.env.REACT_APP_CMS_ACCOUNTING_DOMAIN,
  API_COMMON: process.env.REACT_APP_API_COMMON,
};

export default configs;
