import { useQuery } from "react-query";

import { getProfile } from "api/profile";
import { IProfile } from "constants/interface";

export default function useProfile(enabled = false) {
  const {
    data: profile,
    refetch: refetchProfile,
    isLoading: loading,
    isFetching: fetchingProfile,
  } = useQuery<IProfile>("profile", getProfile, {
    enabled,
    keepPreviousData: true,
  });
  return { profile, refetchProfile, loading, fetchingProfile };
}
