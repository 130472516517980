export enum Direction {
  Up = 1,
  Down,
  Left,
  Right,
}

export enum CoinManageType {
  IN = 1,
  OUT = -1,
}

export enum ReleaseType {
  ALL,
  ADD = 4,
  DESTROY = 5,
}

export enum TransactionHistoryType {
  ALL,
  ADMIN_TRANSFER = 1,
  WALLET = 2,
  COMMON = 7,
  ALL_COMPANY = 8,
  DIV = 9,
  // POSITION = 11,
  GIFT = 3,
  ADMIN_CREATE = 4,
  ADMIN_CANCEL = 5,
  ADMIN_TRANSFER_WALLET = 6,
  ADMIN_TAKE_COIN = 10,
  BORROWED_COIN = 11,
  WITHDRAW_LOAN_COIN = 12,
  RECOVERY_COIN = 13,
}

export enum TransactionHistoryStatus {
  ALL,
  ACTIVE = 1,
  NOT_CONFIRM = 2,
}

export enum ExpiredType {
  ALL = -1,
  EXPIRED = 0,
  NOT_EXPIRED = 1,
}

export enum UseType {
  ALL = -1,
  USE = 1,
  NOT_USE = 0,
}

export enum UserType {
  CLIENT = "CLIENT",
  ADMIN = "ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export enum NotiType {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
}

export enum UpdateType {
  ADD = "add",
  EDIT = "edit",
}

export enum OptionPushNoti {
  SELECT_ALL = 1,
  SELECT_MANY = 0,
}

export enum GetNotiType {
  TODAY = 1,
  OLD = 2,
}

export enum NotiTime {
  FAST = 2,
  SLOW = 3,
}

export enum SelectType {
  MULTIPLE = "multiple",
  TAG = "tags",
}

export enum ConvertTimeType {
  START_TIME = 1,
  END_TIME = 2,
}

export enum ActionType {
  TRANSACTION = 1,
  PERMISSION_MANAGEMENT = 2,
}

export enum CommonStatus {
  ACTIVE = 1,
  INACTIVE = 0,
  NOT_VERIFY = 2,
  REJECTED = 3,
}

export enum ConvertCoinType {
  SIGNATURE = 1,
  UN_SIGNATURE = 2,
  FLOAT = 3,
}

export enum ErrorCode {
  // Common error
  Unknown_Error = "Unknown_Error",
  Invalid_Input = "Invalid_Input",
  Not_Found = "Not_Found",
  Token_Not_Exist = "Token_Not_Exist",
  Forbidden_Resource = "Forbidden_Resource",
  Unauthorized = "Unauthorized",
  Too_Many_Requests = "Too_Many_Requests",
  Email_Already_Exist = "Email_Already_Exist",
  Email_Or_Password_Not_valid = "Email_Or_Password_Not_valid",
  Resource_Already_Exists = "Resource_Already_Exists",
  Can_Not_Disable_Default_language = "Can_Not_Disable_Default_language",
  The_Allowed_Number_Of_Calls_Has_Been_Exceeded = "The_Allowed_Number_Of_Calls_Has_Been_Exceeded",
  Max_Use_Must_Be_Greater_Than_Total_Used = "Max_Use_Must_Be_Greater_Than_Total_Used",
  /**Message */
  Conversation_Not_Found = "Conversation_Not_Found",
  Message_Not_Found = "Message_Not_Found",
  You_Are_Not_Member_Of_This_Conversation = "You_Are_Not_Member_Of_This_Conversation",
  You_Are_No_Longer_Active_In_This_Conversation = "You_Are_No_Longer_Active_In_This_Conversation",
  Verify_Token_Fail = "Verify_Token_Fail",
  Validate_fail = "Validate_fail",
  User_In_Active = "User_In_Active",
  Auth_Failed = "Auth_Failed",
  Access_Denied = "Access_Denied",
  Email_Already_Exists = "Email_Already_Exists",
  Maximum_Retry_Verification_Code = "Maximum_Retry_Verification_Code",
  Delay_Between_Retry_Required = "Delay_Between_Retry_Required",
  Not_Found_User = "Not_Found_User",
  User_Exist = "User_Exist",
  Duplicate_Old_Password = "Duplicate_Old_Password",
  Token_Expired = "Token_Expired",
  Verification_Code_Invalid = "Verification_Code_Invalid",
  User_Not_Found = "User_Not_Found",
  Task_Duplicate = "Task_Duplicate",
  Task_Not_Found = "Task_Not_Found",
  Update_Task_Fail = "Update_Task_Fail",
  User_Inactive = "User_Inactive",
  User_Not_Security_Code = "User_Not_Security_Code",
  User_Had_Security_Code = "User_Had_Security_Code",
  Old_Security_Code_Incorrect = "Old_Security_Code_Incorrect",
  Gift_Not_Found = "Gift_Not_Found",
  Not_Found_Configuration_Coin = "Not_Found_Configuration_Coin",
  Money_invalid = "Money_invalid",
  Not_Enough_Coin = "Not_Enough_Coin",
  Discount_Code_Not_Found = "Discount_Code_Not_Found",
  Quantity_Is_Out_Code = "Quantity_Is_Out_Code",
  Security_Code_Incorrect = "Security_Code_Incorrect",
  Too_Many_Use_This_Code = "Too_Many_Use_This_Code",
  You_Not_Use_This_Code = "You_Not_Use_This_Code",
  Transaction_Not_Found = "Transaction_Not_Found",
  Discount_Code_Duplicate = "Discount_Code_Duplicate",
  Wallet_Total_Not_Enough_Coin = "Wallet_Total_Not_Enough_Coin",
  Quantity_Is_Out_Gift = "Quantity_Is_Out_Gift",
  You_Not_Use_This_Gift = "You_Not_Use_This_Gift",
  Too_Many_Use_This_Gift = "Too_Many_Use_This_Gift",
  Transaction_Is_Active = "Transaction_Is_Active",
  Discount_Code_Expired = "Discount_Code_Expired",
  Request_SSO_Fail = "Request_SSO_Fail",
  Invalid_Input_Transfer_Coin_SSO = "Invalid_Input_Transfer_Coin_SSO",
  Start_End_Time_Invalid_Input = "Start_End_Time_Invalid_Input",
  Update_Gift_Max_Use_Invalid_Input = "Update_Gift_Max_Use_Invalid_Input",
  Is_Not_Super_Admin = "Is_Not_Super_Admin",
  Not_Transfer_Coin_Self = "Not_Transfer_Coin_Self",
  Missing_Member_Id_In_Header = "Missing_Member_Id_In_Header",
  Ip_Not_Permission_Request = "Ip_Not_Permission_Request",
  Acms_Page_Not_Found = "Acms_Page_Not_Found",
  Acms_Page_Duplicate = "Acms_Page_Duplicate",
  Auth_Acms_Failed = "Auth_Acms_Failed",
}

export enum ConvertUseType {
  DISCOUNT,
  GIFT,
}

export enum Size {
  SMALL = "small",
  DEFAULT = "default",
  LARGE = "large",
}

export enum WalletType {
  TOTAL = 1,
  SWAP_COIN = 2,
  GIFT = 3,
  SERVICE = 4,
  CLASS = 5,
  PRIVATE = 6,
  CANCEL = 7,
  SYSTEM = 8,
}

export enum DebounceTime {
  DEFAULT = 500,
}

export enum SortType {
  CODE = "code",
  NAME = "name",
  EMAIL = "email",
  COIN = "coin",
  CREATED_AT = "createdAt",
  FROM_NAME = "fromName",
  TO_NAME = "toName",
  MESSAGE = "message",
  TRANSACTION_CODE = "transactionCode",
  TYPE = "type",
  TO_EMAIL = "toEmail",
  FROM_EMAIL = "fromEmail",
  DISCOUNT_CODE_ID = "discountCodeId",
  WALLET_NAME = "walletName",
  NUMBER_RECEIVER = "numberReceiver",
  CREATED_BY_EMAIL = "createdByEmail",
  DISCOUNT_CODE = "discountCode",
  DISCOUNT = "discount",
  MAX_COIN = "maxCoin",
  TOTAL_USED = "totalUsed",
  EFFECTIVE_START_TIME = "effectiveStartTime",
  TITLE = "title",
  MAX_USE = "maxUse",
  CREATED_BY_NAME = "createdByName",
  MEMBER_COUNT = "memberCount",
  UPDATED_AT = "updatedAt",
  MEMBER_CREATED_AT = "member_createdAt",
  COUNT_MEMBER = "countMember",
  LAST_DAY_BORROW_COIN = "lastDayBorrowCoin",
  BORROWED_COIN = "borrowedCoin",
}

export enum ConditionTargetType {
  ALL = 1,
  COMMON = 2,
  POSITION = 3,
}

export enum SelectDateType {
  DAY = 1,
  WEEK = 2,
  MONTH = 3,
  YEAR = 4,
}

export enum TimeUseOptionType {
  NO_TIME_USE = 0,
  TIME_USE = 1,
}

export enum PerPersonOptionType {
  UNLIMITED = 0,
  LIMITED = 1,
}

export enum PermissionType {
  DASHBOARD_VIEW = "DASHBOARD_VIEW",
  SYSTEM_VIEW = "SYSTEM_VIEW",
  WALLET_MEMBER_VIEW = "WALLET_MEMBER_VIEW",
  WALLET_SYSTEM_VIEW = "WALLET_SYSTEM_VIEW",
  TRANSACTION_VIEW = "TRANSACTION_VIEW",
  TRANSFER_COIN_SYSTEM_VIEW = "TRANSFER_COIN_SYSTEM_VIEW",
  DISCOUNT_VIEW = "DISCOUNT_VIEW",
  GIFT_VIEW = "GIFT_VIEW",
  PERMISSION_CRUD = "PERMISSION_CRUD",
  SYSTEM_CRUD_COIN = "SYSTEM_CRUD_COIN",
  WALLET_SYSTEM_CRUD_COIN = "WALLET_SYSTEM_CRUD_COIN",
  TRANSFER_COIN_SYSTEM_CRUD = "TRANSFER_COIN_SYSTEM_CRUD",
  DISCOUNT_CRUD = "DISCOUNT_CRUD",
  GIFT_CRUD = "GIFT_CRUD",
  PERMISSION_ACCESS_DENIED = "PERMISSION_ACCESS_DENIED",
  DASHBOARD_ACCESS_DENIED = "DASHBOARD_ACCESS_DENIED",
  SYSTEM_ACCESS_DENIED = "SYSTEM_ACCESS_DENIED",
  WALLET_MEMBER_ACCESS_DENIED = "WALLET_MEMBER_ACCESS_DENIED",
  WALLET_SYSTEM_ACCESS_DENIED = "WALLET_SYSTEM_ACCESS_DENIED",
  TRANSACTION_ACCESS_DENIED = "TRANSACTION_ACCESS_DENIED",
  TRANSFER_COIN_SYSTEM_ACCESS_DENIED = "TRANSFER_COIN_SYSTEM_ACCESS_DENIED",
  DISCOUNT_ACCESS_DENIED = "DISCOUNT_ACCESS_DENIED",
  GIFT_ACCESS_DENIED = "GIFT_ACCESS_DENIED",
  ADVANCE_MONEY_ACCESS_DENIED = "ADVANCE_MONEY_ACCESS_DENIED",
  ADVANCE_MONEY_VIEW = "ADVANCE_MONEY_VIEW",
}

export enum TabKey {
  TAB_1 = "1",
  TAB_2 = "2",
}

export enum DateType {
  DATE = "DD/MM/YYYY",
  DATE_TIME = "HH:mm DD/MM/YYYY",
}

export enum ConfigurationKey {
  COIN = 1,
  MAX_LOAN_COIN = 2,
}

export enum QueryKey {
  MEMBER_BORROWED_COIN_LIST = "memberBorrowedCoinList",
  ADVANCE_COIN = "advanceCoin",
  BORROWED_LOAN_COIN_LIST = "borrowedLoanCoinList",
  HISTORY_BORROWED_LOAN_COIN_LIST = " historyBorrowedLoanCoinList",
}
