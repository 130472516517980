import { Popover, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import ME_ICON from "assets/images/acms-me.svg";
import BO_ICON from "assets/images/acms-bo.svg";
import WORKFLOW_ICON from "assets/images/acms-workflow.svg";
import SwitchAppIcon from "assets/images/switch-app.svg";
import styles from "./styles.module.scss";
import configs from "constants/config";
import { IProfile, ISystem } from "constants/interface";

interface IProps {
  profile?: IProfile;
  listSystem?: any;
}

export default function SwitchApp({ profile, listSystem }: IProps) {
  const apps = [
    {
      id: 1,
      image: ME_ICON,
      title: "ACMS ME",
      subTitle: "Cổng nhân viên",
      systemId: 1,
      url: configs.CMS_ME_DOMAIN,
    },
    {
      id: 2,
      image: BO_ICON,
      title: "ACMS BO",
      subTitle: "Nhân sự",
      systemId: 2,
      url: configs.CMS_BO_DOMAIN,
    },
    {
      id: 3,
      image: WORKFLOW_ICON,
      title: "ACMS Workflow",
      subTitle: "Tạo đề xuất",
      systemId: 4,
      url: configs.CMS_WORK_FLOW_DOMAIN,
    },
  ];

  const titleInfo = (
    <div className="d-flex-ali-start">
      <div className="avatar-61 mr-10 mb-14">
        <Avatar src={profile?.toAvatar} icon={<UserOutlined />} />
      </div>
      <div className={styles.infoSwitchApp}>
        <h3>{profile?.toName}</h3>
        <p>{profile?.toEmail}</p>
        <div>
          <span>{profile?.positionName} - </span>
          <span>{profile?.toDivName}</span>
        </div>
      </div>
    </div>
  );

  const AppSwitch = (app: ISystem) => (
    <a href={app?.url} className={styles.appSwitch}>
      <img src={app.image} alt="" className={styles.imageSystem} />
      <h3>{app.title}</h3>
      <p>{app.subTitle}</p>
    </a>
  );

  const contentInfo = (
    <div className={styles.appSwitchContainer}>
      {(listSystem?.data?.length > 0 ? listSystem?.data : apps).map(
        (app: ISystem) => (
          <AppSwitch {...app} key={app.id} />
        )
      )}
    </div>
  );

  return (
    <Popover
      overlayClassName={styles.switchAppContainer}
      placement="bottomRight"
      title={titleInfo}
      content={contentInfo}
      trigger={["click"]}
      className="popoverCustom"
    >
      <div className={styles.switchApp}>
        <img src={SwitchAppIcon} alt="" />
      </div>
    </Popover>
  );
}
