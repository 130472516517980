import { Suspense, useMemo } from "react";
import { Link, useOutlet } from "react-router-dom";
import { MenuProps } from "antd";
import { useTranslation } from "react-i18next";

import SubHeader from "components/SubHeader";

export default function AdvanceCoinManageWrapper() {
  const outlet = useOutlet();
  const [t] = useTranslation();

  const items: MenuProps["items"] = useMemo(() => {
    return [
      {
        key: "list",
        label: (
          <Link to="/advance-coin-manage/list">{t("tab.advanceCoinList")}</Link>
        ),
      },
      {
        key: "history",
        label: (
          <Link to="/advance-coin-manage/history">
            {t("tab.advanceCoinHistory")}
          </Link>
        ),
      },
    ];
  }, []);

  return (
    <div className="sub-wrapper">
      <div className="sub-menu">
        <SubHeader items={items} />
      </div>
      <Suspense fallback={null}>{outlet}</Suspense>
    </div>
  );
}
