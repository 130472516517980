import React from "react";
import { Modal, Button } from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

interface IProps {
  icon?: string;
  title?: string;
  content: string;
  isConfirmModal: boolean;
  setIsConfirmModal: Function;
  handleConfirm: Function;
  loading?: boolean;
  isAlert?: boolean;
}

export default function ModalConfirm(props: IProps) {
  const [t] = useTranslation();

  return (
    <Modal
      title=""
      footer={null}
      visible={props.isConfirmModal}
      onCancel={() => props.setIsConfirmModal(false)}
      closable={false}
      className={classNames("w-345 modal--confirm", {
        "modal--confirm-logout": !props.title,
      })}
    >
      <div className={styles.content}>
        <div className={styles.icon}>
          <img src={props.icon} alt="" />
        </div>
        {props.title ? <h3>{props.title}</h3> : null}
        <p>{props.content}</p>
      </div>
      {props.isAlert ? (
        <div className={styles.footerAlert}>
          <button
            className={styles.footerAlert__btn}
            onClick={() => {
              props.setIsConfirmModal(false);
              props.handleConfirm();
            }}
          >
            {t("btn.ok")}
          </button>
        </div>
      ) : (
        <div className={styles.footer}>
          <Button
            className="btn btn--cancel"
            onClick={() => props.setIsConfirmModal(false)}
            disabled={props.loading}
          >
            {t("btn.cancel")}
          </Button>
          <Button
            className="btn ml-20 btn--confirm"
            onClick={() => props.handleConfirm()}
            disabled={props.loading}
          >
            {t("btn.confirm")}
          </Button>
        </div>
      )}
    </Modal>
  );
}
