import { Menu, Avatar } from "antd";
import type { MenuProps } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";

import styles from "./styles.module.scss";
import AmelaLogo from "assets/images/amela-logo.svg";
import LogoutIcon from "assets/images/logout-icon.svg";
import ModalConfirm from "components/ModalConfirm";
import { logout } from "utils/helper/authentication";
import Notification from "../Notification";
import SwitchApp from "../SwitchApp";
import { IProfile } from "constants/interface";
import useSystem from "utils/hooks/useSystem";
import usePermission from "utils/hooks/usePermission";
import { PermissionType, UserType } from "constants/enum";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

export interface INotiInfoItem {
  is_viewed: number;
  system_id: number;
  total: number | null;
}
interface IProps {
  profile?: IProfile;
}

export default React.memo(function PageHeader({ profile }: IProps) {
  const location = useLocation();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const listSystem = useSystem();
  const { permissions, fetchingPermissions } = usePermission();
  const [curItems, setCurItems] = useState<MenuProps["items"]>([]);
  const queryClient = useQueryClient();

  const checkCoinManage = () => {
    if (permissions?.data && !fetchingPermissions) {
      if (!permissions?.data?.includes(PermissionType.SYSTEM_ACCESS_DENIED)) {
        return "/coin-manage/system-coin";
      }
      if (
        !permissions?.data?.includes(PermissionType.WALLET_MEMBER_ACCESS_DENIED)
      ) {
        return "/coin-manage/employee-wallet";
      }
      if (
        !permissions?.data?.includes(PermissionType.WALLET_SYSTEM_ACCESS_DENIED)
      ) {
        return "/coin-manage/system-wallet";
      }
    }
    return "/coin-manage/system-coin";
  };
  const checkSetting = () => {
    if (permissions?.data && !fetchingPermissions) {
      if (!permissions?.data?.includes(PermissionType.DISCOUNT_ACCESS_DENIED)) {
        return "/settings/discount";
      }
      if (!permissions?.data?.includes(PermissionType.GIFT_ACCESS_DENIED)) {
        return "/settings/gift";
      }
      if (
        !permissions?.data?.includes(PermissionType.PERMISSION_ACCESS_DENIED)
      ) {
        return "/settings/decentralization";
      }
    }
    return "/settings/discount";
  };

  const items: MenuProps["items"] = [
    {
      key: "dashboard",
      label: <Link to="/dashboard">{t("tab.dashboard")}</Link>,
    },
    {
      key: "coin-manage",
      label: <Link to={checkCoinManage()}>{t("tab.coinManage")}</Link>,
    },
    {
      key: "transaction-history",
      label: (
        <Link to="/transaction-history">{t("tab.transactionHistory")}</Link>
      ),
    },
    {
      key: "transfer-coin",
      label: <Link to="transfer-coin/history">{t("tab.transferCoin")}</Link>,
    },
    {
      key: "advance-coin-manage",
      label: (
        <Link to="advance-coin-manage/list">{t("tab.advanceCoinManage")}</Link>
      ),
    },
    {
      key: "settings",
      label: <Link to={checkSetting()}>{t("tab.settings")}</Link>,
    },
  ];

  useEffect(() => {
    let itemsTemp = items;
    if (profile && profile?.userType === UserType.SUPER_ADMIN) {
      return setCurItems(items);
    }
    if (permissions?.data?.length === 0) {
      return setCurItems([]);
    }
    if (permissions?.data && !fetchingPermissions) {
      if (permissions?.data?.includes(PermissionType.DASHBOARD_ACCESS_DENIED)) {
        itemsTemp = itemsTemp.filter((item) => item?.key !== "dashboard");
      }
      if (
        permissions?.data?.includes(PermissionType.SYSTEM_ACCESS_DENIED) &&
        permissions?.data?.includes(
          PermissionType.WALLET_MEMBER_ACCESS_DENIED
        ) &&
        permissions?.data?.includes(PermissionType.WALLET_SYSTEM_ACCESS_DENIED)
      ) {
        itemsTemp = itemsTemp.filter((item) => item?.key !== "coin-manage");
      }
      if (
        permissions?.data?.includes(PermissionType.TRANSACTION_ACCESS_DENIED)
      ) {
        itemsTemp = itemsTemp.filter(
          (item) => item?.key !== "transaction-history"
        );
      }
      if (
        permissions?.data?.includes(
          PermissionType.TRANSFER_COIN_SYSTEM_ACCESS_DENIED
        )
      ) {
        itemsTemp = itemsTemp.filter((item) => item?.key !== "transfer-coin");
      }
      if (
        permissions?.data?.includes(PermissionType.ADVANCE_MONEY_ACCESS_DENIED)
      ) {
        itemsTemp = itemsTemp.filter(
          (item) => item?.key !== "advance-coin-manage"
        );
      }
      if (
        permissions?.data?.includes(PermissionType.DISCOUNT_ACCESS_DENIED) &&
        permissions?.data?.includes(PermissionType.GIFT_ACCESS_DENIED) &&
        permissions?.data?.includes(PermissionType.PERMISSION_ACCESS_DENIED)
      ) {
        itemsTemp = itemsTemp.filter((item) => item?.key !== "settings");
      }
      setCurItems(itemsTemp);
    }
  }, [fetchingPermissions]);

  const handleConfirm = () => {
    logout();
    setIsVisibleModal(false);
  };
  const handleAmelaLogoClick = () => {
    queryClient.invalidateQueries("profile");
    navigate("/");
  };

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.amelaLogoContainer} onClick={handleAmelaLogoClick}>
        <div className={styles.amelaLogo}>
          <img src={AmelaLogo} alt="" />
        </div>
        <span className={styles.appName}>Coin</span>
      </div>
      <Menu
        mode="horizontal"
        selectedKeys={[location.pathname.split("/")[1]]}
        className={styles.menuWrapper}
        items={curItems}
        disabledOverflow
      ></Menu>
      <div className={styles.userInfo}>
        <div className="avatar-40 pr-20">
          <Avatar src={profile?.toAvatar} icon={<UserOutlined />} />
        </div>
        <div className={styles.notification}>
          <Notification userId={profile?.toMemberId} listSystem={listSystem} />
        </div>
        <div className={styles.switchApp}>
          <SwitchApp profile={profile} listSystem={listSystem} />
        </div>
        <div className={styles.logout} onClick={() => setIsVisibleModal(true)}>
          <img src={LogoutIcon} alt="" />
        </div>
      </div>
      {isVisibleModal && (
        <ModalConfirm
          isConfirmModal={isVisibleModal}
          setIsConfirmModal={setIsVisibleModal}
          content="Bạn có chắc chắn muốn đăng xuất không ?"
          handleConfirm={handleConfirm}
        />
      )}
    </div>
  );
});
