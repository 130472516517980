import { IFilter, IProfile, MemberRow } from "constants/interface";
import moment, { Moment } from "moment";
import { notification } from "antd";
import _, { get } from "lodash";
import { KeyboardEvent } from "react";
import i18next from "i18next";

import {
  TransactionHistoryStatus,
  TransactionHistoryType,
  ExpiredType,
  NotiType,
  ConvertTimeType,
  ConvertCoinType,
  ConvertUseType,
  PermissionType,
} from "constants/enum";
import { imageObjs } from "assets/images";

export const getErrorMessage = (error: any) => {
  return (
    error?.response?.data?.errorMessage ||
    error?.message ||
    "Something went wrong!"
  );
};

export const notiCustom = (
  message: string,
  type: NotiType,
  duration?: number
) => {
  notification.destroy();
  notification[type]({
    message: message,
    duration: duration || 3,
  });
};

export const convertAmountCoin = (coin?: number, option?: ConvertCoinType) => {
  if (coin === undefined || coin === null) {
    return "";
  }
  if (option === ConvertCoinType.FLOAT) {
    return `${coin.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }
  if (coin > 0 && option === ConvertCoinType.SIGNATURE) {
    return `+${coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }
  return `${coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const convertAmountCoinStyle = (coin?: number) => {
  if (!coin) {
    return {};
  }
  if (coin > 0) {
    return {
      color: "#2ECC71",
    };
  }
  return {
    color: "#E74C3C",
  };
};

export const convertTransactionHistoryStatusStyle = (
  status?: TransactionHistoryStatus
) => {
  if (!status) {
    return {
      color: "#FFFFFF",
    };
  }
  if (status === TransactionHistoryStatus.ACTIVE) {
    return {
      color: "#55CFAA",
    };
  }
  if (status === TransactionHistoryStatus.NOT_CONFIRM) {
    return {
      color: "#FFC065",
    };
  }
};

export const convertTransactionHistoryStatus = (
  status?: TransactionHistoryStatus
) => {
  if (!status) {
    return "";
  }
  if (status === TransactionHistoryStatus.ACTIVE) {
    return "Đã hoàn thành";
  }
  if (status === TransactionHistoryStatus.NOT_CONFIRM) {
    return "Chưa hoàn thành";
  }
};

export const convertTransactionHistoryType = (
  type?: TransactionHistoryType
) => {
  if (!type) {
    return {
      msg: "",
      icon: null,
    };
  }
  if (type === TransactionHistoryType.COMMON) {
    return {
      msg: i18next.t("transactionType.transferCoin"),
      icon: imageObjs.ArrangeCircle,
    };
  }
  if (type === TransactionHistoryType.ADMIN_TRANSFER) {
    return {
      msg: i18next.t("transactionType.adminTransfer"),
      icon: imageObjs.ArrangeCircle,
    };
  }
  if (type === TransactionHistoryType.GIFT) {
    return {
      msg: i18next.t("transactionType.exchangeGift"),
      icon: imageObjs.GiftIcon,
    };
  }
  if (type === TransactionHistoryType.RECOVERY_COIN) {
    return {
      msg: i18next.t("transactionType.recoveryCoin"),
      icon: imageObjs.recoveryCoinIcon,
    };
  }
};

export const convertExpiredType = (endTime?: string) => {
  const type = moment().isAfter(endTime)
    ? ExpiredType.EXPIRED
    : ExpiredType.NOT_EXPIRED;
  if (type === ExpiredType.EXPIRED) {
    return {
      color: "#E74C3C",
    };
  }
  if (type === ExpiredType.NOT_EXPIRED) {
    return {
      color: "#000",
    };
  }
};

export const isExpired = (endTime?: string) => {
  return moment().isAfter(endTime);
};

export const isUsed = (totalUse: number, maxUse: number) => {
  if (maxUse === null || totalUse < maxUse) {
    return true;
  }
  return false;
};

export const convertExpiredName = (endTime?: string) => {
  const type = moment().isAfter(endTime)
    ? ExpiredType.EXPIRED
    : ExpiredType.NOT_EXPIRED;
  if (type === ExpiredType.EXPIRED) {
    return "Hết hạn";
  }
  if (type === ExpiredType.NOT_EXPIRED) {
    return "Còn hạn";
  }
};

export const convertUseType = (totalUse: number, maxUse: number) => {
  if (maxUse === null || totalUse < maxUse) {
    return {
      color: "#000",
    };
  }
  return {
    color: "#E74C3C",
  };
};

export const convertUseName = (
  totalUsed: number,
  maxUse: number,
  type: ConvertUseType
) => {
  if (maxUse === null || totalUsed < maxUse) {
    return type === ConvertUseType.DISCOUNT ? "Còn mã" : "Còn hàng";
  }
  return type === ConvertUseType.DISCOUNT ? "Hết mã" : "Hết hàng";
};

export const convertTimeRange = (timeStart?: string, timeEnd?: string) => {
  if (!timeEnd || !timeStart) {
    return "Không thời hạn";
  }
  return `${moment(timeStart).format("DD/MM/YYYY").toString()}~${moment(timeEnd)
    .format("DD/MM/YYYY")
    .toString()}`;
};

export const getBase64 = (img: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result as string));
    reader.readAsDataURL(img);
  });

export const convertTimesToUTC = (times?: {}) => {
  const timeConvert = _.mapValues(times, (value: Moment, key: string) => {
    if (value && (key === "startTime" || key === "effectiveStartTime")) {
      return value.startOf("date").toISOString();
    }
    if (value && (key === "endTime" || key === "effectiveEndTime")) {
      return value.endOf("date").toISOString();
    }
    return value;
  });
  return timeConvert;
};

export const compareDate = (date1: Moment, date2: Moment) => {
  return date1.isSame(date2, "day");
};

export const convertTimeToUTC = (
  type: ConvertTimeType,
  time?: Moment | null,
  format?: string
) => {
  if (!time) {
    return undefined;
  }
  return type === ConvertTimeType.START_TIME
    ? time
        .startOf((format as moment.unitOfTime.StartOf) || "date")
        .toISOString()
    : time.endOf((format as moment.unitOfTime.StartOf) || "date").toISOString();
};

export const handleKeyDownCoin = (e: KeyboardEvent<HTMLInputElement>) => {
  const key = e.which || e.keyCode; // keyCode detection
  const value = parseInt(get(e, "target.value", "").replaceAll("%", "") + "");
  if (key === 8 || key === 9 || key === 46) {
    // backspace, tab, delete
    return;
  }
  const ctrl = e.ctrlKey ? e.ctrlKey : key === 17 ? true : false; // ctrl detection
  if (key === 86 && ctrl) {
    // Ctrl + V
    return;
  } else if (key === 67 && ctrl) {
    // Ctrl + C
    return;
  } else if (key === 65 && ctrl) {
    // Ctrl + A
    return;
  }

  if ((get(e, "target.value", "").replaceAll(",", "") + "").length >= 10) {
    e.preventDefault();
    return;
  }
  if ((key === 96 || key === 48) && Number.isNaN(value)) {
    e.preventDefault();
    return;
  }
  if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
    // 0-9 only
    return;
  }
  e.preventDefault();
};

export const convertUTCToLocalString = (time?: string, format?: string) => {
  if (!time) return "";
  return moment(time)
    .format(format || "DD/MM/YYYY HH:mm")
    .toString();
};

export const convertDiscountNumber = (discount?: number) => {
  if (!discount) {
    return "";
  }
  return discount + "%";
};

export const handleKeyDownDiscount = (e: KeyboardEvent<HTMLInputElement>) => {
  const key = e.which || e.keyCode; // keyCode detection
  const lengthValue = (get(e, "target.value", "").replaceAll("%", "") + "")
    .length;
  const value = parseInt(get(e, "target.value", "").replaceAll("%", "") + "");
  if (key === 8 || key === 9 || key === 46) {
    // backspace, tab, delete
    return;
  }
  const ctrl = e.ctrlKey ? e.ctrlKey : key === 17 ? true : false; // ctrl detection
  if (key === 86 && ctrl) {
    // Ctrl + V
    return;
  } else if (key === 67 && ctrl) {
    // Ctrl + C
    return;
  } else if (key === 65 && ctrl) {
    // Ctrl + A
    return;
  }

  if (lengthValue >= 2 && value !== 10) {
    e.preventDefault();
    return;
  }
  if ((key === 96 || key === 48) && Number.isNaN(value)) {
    e.preventDefault();
    return;
  }
  if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
    // 0-9 only
    return;
  }
  e.preventDefault();
};

export const convertVietnamese = (str?: string) => {
  if (!str) {
    return "";
  }
  let newStr = str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .trim();
  return newStr;
};

export const convertReleaseType = (type?: number) => {
  if (!type) return "";
  if (type === TransactionHistoryType.ADMIN_CREATE) {
    return "Phát hành coin";
  }
  if (type === TransactionHistoryType.ADMIN_CANCEL) {
    return "Hủy coin";
  }
  if (type === TransactionHistoryType.ADMIN_TRANSFER_WALLET) {
    return "Admin chuyển coin sang ví khác";
  }
  if (type === TransactionHistoryType.ADMIN_TAKE_COIN) {
    return "Admin rút coin về ví tổng";
  }
  if (type === TransactionHistoryType.GIFT) {
    return "Đổi quà";
  }
  if (type === TransactionHistoryType.ADMIN_TRANSFER) {
    return "Admin chuyển tiền";
  }
  if (type === TransactionHistoryType.COMMON) {
    return "Chuyển tiền";
  }
  if (type === TransactionHistoryType.BORROWED_COIN) {
    return i18next.t("transactionType.borrowedCoin");
  }
  if (type === TransactionHistoryType.WITHDRAW_LOAN_COIN) {
    return i18next.t("transactionType.withdrawLoanCoin");
  }
  if (type === TransactionHistoryType.RECOVERY_COIN) {
    return i18next.t("transactionType.recoveryCoin");
  }
};

export const searchText = (texts: string[], input?: string) => {
  let result = false;
  texts.forEach((text?: string) => {
    if (convertVietnamese(text).includes(convertVietnamese(input))) {
      result = true;
    }
  });
  return result;
};

export const getSortValue = (sort?: boolean) => {
  if (sort) {
    return {
      icon: imageObjs.SortTop,
      value: false,
    };
  }
  if (sort === false) {
    return {
      icon: imageObjs.SortDown,
    };
  }
  return {
    icon: imageObjs.SortIcon,
    value: true,
  };
};

export const getListGroup = (listGroupName?: any) => {
  const listGroup = listGroupName;
  listGroup?.map((group: any) => {
    return Object.assign(group, {
      name: group["group_name"],
      id: group["group_id"],
    })["group_name"];
  });
  return listGroup;
};

export const isUserExits = (id: number, members: MemberRow[]) => {
  return members.some((member: MemberRow) => member.user_id === id);
};

export const isUserExitsId = (id: number, ids: number[]) => {
  return ids.some((cId: number) => cId === id);
};

export const sumArrayNumber = (arr: number[]) => {
  return arr.reduce((pre: number, next: number) => pre + next, 0);
};

export const convertNumberLarge = (num: number) => {
  if (num >= 1000 && num < 10000) {
    return convertAmountCoin(num);
  }
  if (num >= 10000 && num < 1000000) {
    return `${convertAmountCoin(
      Math.round((num / 1000) * 100) / 100,
      ConvertCoinType.FLOAT
    )}k`;
  }
  if (num >= 1000000 && num < 1000000000) {
    return `${convertAmountCoin(
      Math.round((num / 1000000) * 100) / 100,
      ConvertCoinType.FLOAT
    )}tr`;
  }
  if (num >= 1000000000) {
    return `${convertAmountCoin(
      Math.round((num / 1000000000) * 100) / 100,
      ConvertCoinType.FLOAT
    )}tỷ`;
  }
  return num;
};

export const getLeftLabel = (data: number[]) => {
  const maxValue = Math.max(...data);
  return convertAmountCoin(maxValue).length * 6;
};

export const getIndexTable = (
  pageIndex: number,
  pageSize: number,
  current: number
) => {
  return (pageIndex - 1) * pageSize + current + 1;
};

export const checkArrayInArray = (arr1: number[], arr2: number[]) => {
  if (arr1.length === 0 || arr2.length === 0) return false;
  for (const num of arr1) {
    if (!isUserExitsId(num, arr2)) return false;
  }
  return true;
};

export const formatText = (str?: string) => {
  if (!str) return "";
  return str.trim().replace(/\s+/gm, " ");
};

export const handleDataBeforeCallApi = (filter: IFilter) => {
  const times = _.pick(filter, ["startTime", "endTime"]);
  return {
    ...filter,
    ...convertTimesToUTC(times),
  };
};
