import { useEffect, Suspense } from "react";
import { useOutlet, useNavigate, useLocation } from "react-router-dom";

import PageHeader from "components/PageHeader";
import styles from "./styles.module.scss";
import storageFC from "utils/helper/storage";
import useProfile from "utils/hooks/useProfile";
import useLogin, { logout } from "utils/helper/authentication";
import { PermissionType, UserType } from "constants/enum";
import usePermission from "utils/hooks/usePermission";

export default function PageWrapper() {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const location = useLocation();
  const { loadingLogin, loginCoin } = useLogin();
  const isAuthenticated = !!storageFC.getToken();
  const { profile, fetchingProfile } = useProfile(isAuthenticated);
  const { permissions, fetchingPermissions } = usePermission();

  useEffect(() => {
    if (location.pathname === "/") {
      if (profile && profile?.userType === UserType.SUPER_ADMIN) {
        return navigate("/dashboard");
      }
      if (permissions?.data && !fetchingPermissions) {
        if (
          !permissions?.data?.includes(PermissionType.DASHBOARD_ACCESS_DENIED)
        ) {
          return navigate("/dashboard");
        }
        if (!permissions?.data?.includes(PermissionType.SYSTEM_ACCESS_DENIED)) {
          return navigate("/coin-manage/system-coin");
        }
        if (
          !permissions?.data?.includes(
            PermissionType.WALLET_MEMBER_ACCESS_DENIED
          )
        ) {
          return navigate("/coin-manage/employee-wallet");
        }
        if (
          !permissions?.data?.includes(
            PermissionType.WALLET_SYSTEM_ACCESS_DENIED
          )
        ) {
          return navigate("/coin-manage/system-wallet");
        }
        if (
          !permissions?.data?.includes(PermissionType.TRANSACTION_ACCESS_DENIED)
        ) {
          return navigate("/transaction-history");
        }
        if (
          !permissions?.data?.includes(
            PermissionType.TRANSFER_COIN_SYSTEM_ACCESS_DENIED
          )
        ) {
          return navigate("/transfer-coin/history");
        }
        if (
          !permissions?.data?.includes(
            PermissionType.ADVANCE_MONEY_ACCESS_DENIED
          )
        ) {
          return navigate("/advance-coin-manage/list");
        }
        if (
          !permissions?.data?.includes(PermissionType.DISCOUNT_ACCESS_DENIED)
        ) {
          return navigate("/settings/discount");
        }
        if (!permissions?.data?.includes(PermissionType.GIFT_ACCESS_DENIED)) {
          return navigate("/settings/gift");
        }
        if (
          !permissions?.data?.includes(PermissionType.PERMISSION_ACCESS_DENIED)
        ) {
          return navigate("/settings/decentralization");
        }
      }
    }
  }, [fetchingProfile]);

  if (!isAuthenticated && !loadingLogin) {
    if (!storageFC.getItem("token")) {
      logout();
    } else {
      loginCoin();
    }
    return null;
  }
  if (!profile) return null;

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.mainWrapper}>
        <PageHeader profile={profile} />
        <div className={styles.pageContent}>
          <Suspense fallback={null}>{outlet}</Suspense>
        </div>
      </div>
    </div>
  );
}
