import { useMutation } from "react-query";

import { history } from "../../App";
import storageFC from "./storage";
import { signin } from "api/authentication";
import configs from "constants/config";

export default function useLogin() {
  const { isLoading: loadingLogin, mutate: loginCoin } = useMutation(
    () =>
      signin({
        tokenSSO: storageFC.getItem("token"),
      }),
    {
      onSuccess: (data: any) => {
        if (data?.data && data?.data?.token) {
          storageFC.setToken(data?.data?.token);
          history.push("/");
        }
      },
    }
  );
  return { loadingLogin, loginCoin };
}

export const logout = () => {
  storageFC.clearToken();
  storageFC.removeItem("token");
  storageFC.setItem("previous_url", configs.CMS_COIN_DOMAIN);
  window.location.href = configs.API_DOMAIN_LOGIN || "";
};
